import React, { useState } from "react";
import Layout from "../layout";
import config from "../../data/SiteConfig";
import Seo from "../components/Seo/Seo";
import StripHTML from "../utils/StripHTML";
// import GImage from "../components/Picture/GImage";
import Activities from "../components/Activities/Activities";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import PdfViewer from "../components/PdfViewer/PdfViewer";
import FormatRangeDate from "../utils/FormatRangeDate.js";
import Meetings from "../components/Activities/Meetings";
import { graphql } from "gatsby";
import axios from "axios";

const ActivityTemplate = (props) => {
  const { directus } = props.data;
  const [downloadingFile, setDownloadingFile] = useState(null);

  const downloadFile = (fileID, title, extension) => {
    console.log("download start...");
    if (downloadingFile === null) {
      setDownloadingFile(fileID);
      axios({
        headers: {
          Authorization: "Bearer " + process.env.GATSBY_API_TOKEN,
        },
        method: "GET",
        url: `${process.env.GATSBY_API_URL}/assets/${fileID}`,
        responseType: "blob",
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", title + "." + extension); //or any other extension
          document.body.appendChild(link);
          link.click();
          console.log("download ok !");
          setDownloadingFile(null);
          // console.log(response);
        })
        .catch((error) => {
          setDownloadingFile(null);
          console.log(error);
        });
    }
  };

  return (
    <Layout>
      <div className="h-20 md:h-28"></div>
      {/* {directus.settings &&
        directus.settings.activities_illustration !== null && (
          <GImage
            className="h-44 md:h-72 bg-activities bg-cover"
            src={directus.settings.activities_illustration.imageFile}
            alt="Image de la page détail d'une activité du secteur langues du Groupe Français d'éducation Nouvelle"
          />
        )} */}
      <div className="activity-container">
        <Seo
          title={directus.activities_by_id.title}
          description={`${directus.activities_by_id.title} : ${
            directus.activities_by_id.intro &&
            directus.activities_by_id.intro.length > 1
              ? StripHTML(directus.activities_by_id.intro)
              : directus.activities_by_id.description &&
                directus.activities_by_id.description.length > 1
              ? StripHTML(directus.activities_by_id.description)
              : "activité du " + config.siteTitleShort
          }`}
          article={true}
        />
        <Breadcrumb location={"activités"} />
        {directus.activities_by_id && (
          <div className="activities max-w-6xl mx-auto px-3 sm:px-6 pt-8 pb-20">
            <div className="mx-auto w-auto">
              <h1 className="text-center mt-6 mb-8">
                {directus.activities_by_id.title}
              </h1>
              <hr className="border-none h-2 bg-red-500" />
              <div className="pt-2 pb-0">
                <p className="flex items-center font-bold text-gray-600">
                  <span className="material-icons-outlined text-gray-600 mr-2">
                    date_range
                  </span>
                  <span className="">
                    {
                      <FormatRangeDate
                        from={directus.activities_by_id.date_from}
                        to={directus.activities_by_id.date_to}
                      />
                    }
                  </span>
                </p>
              </div>
              <div className="pt-1 pb-0">
                <p className="flex items-center font-bold">
                  <span className="material-icons-outlined text-gray-600 mr-2">
                    map
                  </span>
                  {directus.activities_by_id.location}
                </p>
              </div>
              {directus.activities_by_id.intro && (
                <div
                  className="mt-6 text-editor"
                  dangerouslySetInnerHTML={{
                    __html: `${directus.activities_by_id.intro}`,
                  }}
                />
              )}
              {directus.activities_by_id.meetings && (
                <Meetings meetings={directus.activities_by_id.meetings} />
              )}
              {directus.activities_by_id.description && (
                <div
                  className="mt-6 text-editor"
                  dangerouslySetInnerHTML={{
                    __html: `${directus.activities_by_id.description}`,
                  }}
                />
              )}
            </div>
            <div>
              {directus.activities_by_id.documents.length > 0 && (
                <React.Fragment>
                  <h4 className="flex items-center mt-10 mb-1">
                    <span className={`material-icons-outlined pr-2 py-0`}>
                      description
                    </span>
                    Document
                    {directus.activities_by_id.documents.length > 1 && "s"} :
                  </h4>
                  <ul>
                    {directus.activities_by_id.documents.map((document) => {
                      return (
                        document &&
                        document.directus_files_id !== null && (
                          <li
                            key={document.directus_files_id.id}
                            className={`my-2 px-2 bg-white mb-3 shadow-md ${
                              document.directus_files_id.description
                                ? "py-2"
                                : "py-2"
                            }`}
                          >
                            <div className="flex items-center w-full">
                              {document.directus_files_id.imageFile
                                .extension !== "mp3" &&
                              document.directus_files_id.imageFile.extension !==
                                "wav" ? (
                                <a
                                  title="Télécharger le document"
                                  className={`inline-flex items-center px-2 py-2 hover:bg-gray-100`}
                                  target="_blank"
                                  rel="noreferrer"
                                  href={
                                    document.directus_files_id.imageFile
                                      .publicURL
                                  }
                                >
                                  <span className="material-icons-outlined text-red-500">
                                    file_download
                                  </span>
                                </a>
                              ) : (
                                <button
                                  onClick={() => {
                                    downloadFile(
                                      document.directus_files_id.filename_disk,
                                      document.directus_files_id.title,
                                      document.directus_files_id.imageFile
                                        .extension
                                    );
                                  }}
                                  className={`inline-flex items-center px-2 py-2 hover:bg-gray-100`}
                                >
                                  {downloadingFile ===
                                  document.directus_files_id.filename_disk ? (
                                    <span className="animate-spin material-icons-outlined text-red-500">
                                      sync
                                    </span>
                                  ) : (
                                    <span className="material-icons-outlined text-red-500">
                                      file_download
                                    </span>
                                  )}
                                </button>
                              )}
                              {document.directus_files_id.imageFile
                                .publicURL && (
                                <PdfViewer
                                  title={document.directus_files_id.title}
                                  extension={
                                    document.directus_files_id.imageFile
                                      .extension
                                  }
                                  textClass="text-red-500"
                                  pdfFile={
                                    document.directus_files_id.imageFile
                                      .publicURL
                                  }
                                />
                              )}

                              {/* <span className="material-icons-outlined text-red-500 pr-2">
                          description
                        </span> */}
                              <span className="ml-2 font-normal text-base">
                                {document.directus_files_id.title}
                              </span>
                            </div>
                            {document.directus_files_id.description && (
                              <p className="pb-2 px-2">
                                <i className="text-gray-600 text-sm">
                                  {document.directus_files_id.description}
                                </i>
                              </p>
                            )}
                          </li>
                        )
                      );
                    })}
                  </ul>
                </React.Fragment>
              )}
            </div>
          </div>
        )}
      </div>
      <Activities
        page={true}
        evenements={directus.evenements}
        reunions={directus.reunions}
        // illustration={directus.settings.activities_illustration}
      />
    </Layout>
  );
};
export default ActivityTemplate;

export const query = graphql`
  query ($id: ID!, $title: String) {
    directus: allDirectus {
      # settings {
      #   id
      #   activities_illustration {
      #     id
      #     imageFile {
      #       id
      #       childImageSharp {
      #         id
      #         gatsbyImageData(
      #           quality: 80
      #           layout: FULL_WIDTH
      #           placeholder: TRACED_SVG
      #           tracedSVGOptions: { color: "#d8b0b1" }
      #         )
      #       }
      #     }
      #   }
      # }
      evenements: activities(
        sort: ["-date_from", "-date_to"]
        filter: { type: { _eq: "Évènement" }, title: { _neq: $title } }
      ) {
        id
        title
        type
        date_from
        date_to
        meetings
        location
        intro
        description
        documents {
          directus_files_id {
            id
          }
        }
      }
      reunions: activities(
        sort: ["-date_from", "-date_to"]
        filter: { type: { _eq: "Réunion" }, title: { _neq: $title } }
      ) {
        id
        title
        type
        date_from
        date_to
        meetings
        location
        intro
        description
        documents {
          directus_files_id {
            id
          }
        }
      }
      activities_by_id(id: $id) {
        id
        title
        date_from
        date_to
        meetings
        location
        intro
        description
        documents {
          directus_files_id {
            id
            title
            filename_disk
            filename_download
            description
            imageFile {
              id
              extension
              publicURL
              extension
              size
            }
          }
        }
      }
    }
  }
`;
